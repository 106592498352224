import { Box, SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { ReactElement, cloneElement } from "react";
import InfoCard from "../../../components/InfoCard";

interface Props {
  child: ReactElement;
  src: string | undefined;
  message: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

export default function FallbackSrc(props: Props) {
  return props.src ? (
    /*@ts-ignore*/
    cloneElement(props.child, { className: props.className })
  ) : (
    <Box height="100%" width="100%" display="flex" alignItems="stretch">
      <InfoCard text={props.message} icon={props.icon} fullHeight />
    </Box>
  );
}
