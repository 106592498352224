import { makeStyles } from "@material-ui/core";
import React, { ReactElement } from "react";
import theme from "../../../config/theme";

interface Props {
  aspectRatio: Array<number>;
  child: ReactElement;
}

export default function RatioBox(props: Props) {
  const useStyle = makeStyles({
    container: {
      paddingTop: `${(100 * props.aspectRatio[1]) / props.aspectRatio[0]}%`,
      position: "relative",
      width: "100%",
      // backgroundColor: theme.palette.grey[100],
      borderRadius: theme.shape.borderRadius,
      overflow: "hidden",
    },
    wrapper: {
      width: "100%",
      height: "100%",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    child: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  });

  const classes = useStyle();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.wrapper}>{React.cloneElement(props.child, { className: classes.child })}</div>
      </div>
    </>
  );
}
