import { Grid, TextField, TextFieldProps, GridSize } from "@material-ui/core";

type Props = TextFieldProps & { width?: GridSize };

export default function CustomTextField({ width = "auto", ...attributes }: Props) {
  return (
    <Grid item xs={width}>
      <TextField variant="outlined" fullWidth required {...attributes} />
    </Grid>
  );
}
