//    avi Docs :
//          https://cdn.hackaday.io/files/274271173436768/avi.pdf
//			or : http://www.jmcgowan.com/odmlff2.pdf
//			or better : https://docs.microsoft.com/en-us/windows/win32/directshow/avi-riff-file-reference

import { arrayBuffer } from "./misc_util";

export class RIFFParser {
  private dwMicroSecPerFrame?: number;
  private dwWidth?: number;
  private dwHeight?: number;
  private resetposition?: number;
  private parser?: Parser;

  // return an error string or null if no error occurs
  async openMjpg(mjpg: Blob | Uint8Array) {
    // mjpg : Uint8Array or Blob

    if (mjpg instanceof Blob) {
      const arraybuffer = await arrayBuffer(mjpg);
      mjpg = new Uint8Array(arraybuffer as ArrayBufferLike);
    }

    if (!(mjpg instanceof Uint8Array)) {
      return "Bad parameter";
    }

    const parser = new Parser(mjpg);
    if (!parser.enterInList("AVI ")) return "file corrupted";

    if (!parser.enterInList("hdrl")) return "file corrupted";

    if (!parser.enterInChunk("avih")) return "file corrupted";

    // get Frame timing
    this.dwMicroSecPerFrame = parser.readDW();
    // skip dwMaxBytesPerSec, dwPaddingGranularity, dwFlags, dwTotalFrames, dwInitialFrames, dwStreams, dwSuggestedBufferSize
    parser.skip(7 * 4);

    // get Frame size
    this.dwWidth = parser.readDW();
    this.dwHeight = parser.readDW();

    parser.exitElement(); // exit avih
    parser.exitElement(); // exit hdrl

    // position to Image List
    if (!parser.enterInList("movi", true)) return "file corrupted";

    this.resetposition = parser.position;
    this.parser = parser;

    return null;
  }

  getNextFrame() {
    if (!this.parser || !this.parser.enterInChunk("00dc")) return null; //"end";

    const image = this.parser.getData(); // Uint8Array
    this.parser.exitElement(); // exit 00dc

    const blob = new Blob([image.buffer], { type: "image/jpeg" });
    return blob; // Blob
  }

  resetToFirstFrame() {
    if (this.parser) this.parser.position = this.resetposition ?? 0;
  }

  getFramePeriodMs() {
    return this.dwMicroSecPerFrame! / 1000;
  }

  getSize() {
    return [this.dwWidth, this.dwHeight];
  }
}

class Parser {
  data: Uint8Array;
  position: number;
  currentElementPositionStack: number[];
  currentElementSizeStack: number[];

  constructor(riff: Uint8Array) {
    this.data = riff;
    this.position = 0; // current position in file
    this.currentElementPositionStack = []; // contains the position of the current Element (Chunk, or List) and its parents
    this.currentElementSizeStack = []; // contains the size of the current Element (Chunk, or List) and its parents
  }

  // recursive methode (recursive if navigate is true) that looks for a specific Chunk
  enterInChunk(chunkName: string, navigate = false): boolean {
    const currentPosition = this.position; // save current position
    const currentChunkName = this.ReadString(this.data, this.position, 4);
    const dwSize = this.ReadInt32_LE(this.data, this.position + 4);
    if (currentChunkName === chunkName) {
      // this the chunk we are looking for. set the position to point to its data and return
      this.currentElementPositionStack.push(this.position);
      this.currentElementSizeStack.push(dwSize + 8);
      this.position += 8;
      return true;
    }
    // the chunk is not at the current position...if navigate is set, we skip the current chunk (or list) and seek again
    if (navigate) {
      this.position += dwSize + 8;
      if (this.position >= this.data.length) return false;
      const found = this.enterInChunk(chunkName, true);
      if (!found) {
        // restore position
        this.position = currentPosition;
      }
      return found;
    }
    return false;
  }

  // recursive methode (recursive if navigate is true) that looks for a specific List
  enterInList(listName: string, navigate = false): boolean {
    const currentPosition = this.position; // save current position
    const name = this.ReadString(this.data, this.position, 4);
    const dwSize = this.ReadInt32_LE(this.data, this.position + 4);

    if (name === "LIST" || name === "RIFF") {
      const currentListName = this.ReadString(this.data, this.position + 8, 4);
      if (currentListName === listName) {
        // this the list we are looking for. set the position to point to its data and return
        this.currentElementPositionStack.push(this.position);
        this.currentElementSizeStack.push(dwSize + 8);
        this.position += 12;
        return true;
      }
    }
    // the list is not at the current position...if navigate is set, we skip the current chunk (or list) and seek again
    if (navigate) {
      this.position += dwSize + 8;
      if (this.position >= this.data.length) return false;
      const found = this.enterInList(listName, true);
      if (!found) {
        // restore position
        this.position = currentPosition;
      }
      return found;
    }
    return false;
  }

  getData() {
    // we assume we heve entered a chunk (there's no need to get data from a list - a list contains chunks)
    //  (for a list content, size would be this.currentElementSizeStack[this.currentElementSizeStack.length - 1] - 12 !)
    let size = this.currentElementSizeStack[this.currentElementSizeStack.length - 1] - 8;
    return this.data.slice(this.position, this.position + size);
  }

  exitElement() {
    const elementPosition = this.currentElementPositionStack.pop();
    const elementSize = this.currentElementSizeStack.pop();
    this.position = elementPosition! + elementSize!;
    if (this.position & 1) this.position++; // padding
  }

  readDW() {
    const ret = this.ReadInt32_LE(this.data, this.position);
    this.position += 4;
    return ret;
  }

  skip(offset: number) {
    this.position += offset;
  }

  ReadString(data: Uint8Array, offset: number, numChar = 10000) {
    var chars = [];
    let n = 0;
    while (data[offset] && n++ < numChar) chars.push(data[offset++]);
    return String.fromCharCode.apply(null, chars);
  }

  ReadInt32_LE(data: Uint8Array, offset: number) {
    let value;
    value = data[offset] & 0xff;
    value += (data[offset + 1] & 0xff) << 8;
    value += (data[offset + 2] & 0xff) << 16;
    value += (data[offset + 3] & 0xff) << 24;
    return value;
  }
}
