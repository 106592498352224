import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 2,
  },
  button: {
    fontSize: 17,
    textAlign: "center",
    margin: 8,
    padding: "8px 15px",
    borderRadius: 10,
    backgroundColor: "#F6F6F6",
    color: "#979797",
    cursor: "pointer",
  },
  selected: {
    fontSize: 17,
    textAlign: "center",
    margin: 8,
    padding: "8px 15px",
    borderRadius: 10,
    backgroundColor: "rgba(83, 185, 209, 0.2)",
    color: "#000000",
    cursor: "pointer",
  },
});

type Props = {
  count: number;
  selectedIdx: number;
  onClick: (index: number) => void;
};

export default function PaginationButtons(props: Props) {
  const classes = useStyles();

  let children = [];
  for (let i = 0; i < props.count; i++) {
    children.push(
      <div
        className={props.selectedIdx === i ? classes.selected : classes.button}
        onClick={() => props.onClick(i)}
        key={`button-${i}`}
      >
        {i + 1}
      </div>
    );
  }
  return <div className={classes.wrapper}>{children}</div>;
}
