import { RIFFParser } from "./riff_parser";

type Rect = {
  x?: number;
  y?: number;
  width: number;
  height: number;
};

export class MjpgPlayer {
  private canvas: HTMLCanvasElement | null = null;
  private videoParser: RIFFParser | null = null;
  private frameTimer: NodeJS.Timeout | null = null;
  private img: HTMLImageElement | null = null;

  private onClick = () => {
    if (this.frameTimer) this.stop();
    else this.start();
  };

  constructor(canvas: HTMLCanvasElement) {
    this.init(canvas);
  }

  init(canvas: HTMLCanvasElement) {
    // Remove previous canvas' event listener
    if (this.canvas) {
      this.canvas.removeEventListener("click", this.onClick, false);
    }

    this.videoParser = null;
    this.frameTimer = null;

    this.canvas = canvas;
    this.img = new Image();
    this.img.onload = () => {
      this.updateFrame(this.img!);
      URL.revokeObjectURL(this.img!.src);
    };

    this.canvas.addEventListener("click", this.onClick, false);
  }

  async open(videoBlob: Blob) {
    this.videoParser = new RIFFParser();
    const errorMsg = await this.videoParser.openMjpg(videoBlob);
    if (errorMsg) {
      console.error("Video Parser Error : ", errorMsg);
      this.videoParser = null;
    }
    return errorMsg;
  }

  start() {
    if (!this.videoParser || !this.canvas) return;

    const [width, height] = this.videoParser.getSize();
    if (width) this.canvas.width = width;
    if (height) this.canvas.height = height;

    this.videoParser.resetToFirstFrame();
    const period = this.videoParser.getFramePeriodMs();

    this.frameTimer = setInterval(() => {
      const imgBlob = this.videoParser?.getNextFrame();
      if (!imgBlob) this.stop();
      else this.img!.src = URL.createObjectURL(imgBlob);
    }, period);
  }

  stop() {
    if (this.frameTimer) clearInterval(this.frameTimer);
    this.frameTimer = null;
  }

  updateFrame(img: HTMLImageElement) {
    var srcRect = {
      x: 0,
      y: 0,
      width: img.naturalWidth,
      height: img.naturalHeight,
    };
    var dstRect = this._scaleRect(srcRect, {
      width: this.canvas!.width,
      height: this.canvas!.height,
    });
    //console.log(srcRect, dstRect, this.canvas.width, this.canvas.height);

    let context = this.canvas?.getContext("2d");
    try {
      context?.drawImage(
        img,
        srcRect.x,
        srcRect.y,
        srcRect.width,
        srcRect.height,
        dstRect.x,
        dstRect.y,
        dstRect.width,
        dstRect.height
      );
    } catch (e) {
      // if we can't draw, don't bother updating anymore
      this.stop();
      throw e;
    }
  }

  _scaleRect(srcSize: Rect, dstSize: Rect) {
    var ratio = Math.min(dstSize.width / srcSize.width, dstSize.height / srcSize.height);
    var newRect = {
      x: 0,
      y: 0,
      width: srcSize.width * ratio,
      height: srcSize.height * ratio,
    };
    newRect.x = dstSize.width / 2 - newRect.width / 2;
    newRect.y = dstSize.height / 2 - newRect.height / 2;
    return newRect;
  }
}
