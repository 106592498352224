import { Box, SvgIconTypeMap, Typography } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import React from "react";

type Props = {
  text: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  fullHeight?: boolean;
};

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.black,
    width: "100%",
  },
  content: {
    display: "flex",
    alignItems: "center",
    flex: "1",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  icon: {
    marginRight: 0,
    color: theme.palette.grey[500],
  },
}));

export default function InfoCard(props: Props) {
  const classes = useStyles();

  return (
    <div
      className={classes.container}
      style={{
        flex: props.fullHeight ? "1" : "0",
      }}
    >
      <div>{props.fullHeight}</div>
      <div className={classes.content}>
        {props.icon ? (
          <props.icon fontSize="large" className={classes.icon} />
        ) : (
          <InfoIcon fontSize="large" className={classes.icon}></InfoIcon>
        )}
        <Box m={0.5} />
        <Typography>{props.text}</Typography>
      </div>
    </div>
  );
}
