import { Dispatch, SetStateAction, useEffect, useState } from "react";

export type StorageType = "local" | "session";

// Note: this is an adaptation of the approach described in
// https://dev.to/selbekk/persisting-your-react-state-in-9-lines-of-code-9go
export function usePersistedState<T>(
  key: string,
  defaultValue: T,
  storageType: StorageType = "local"
): [T, Dispatch<SetStateAction<T>>, () => void] {
  const [state, setState] = useState<T>(() => load(storageType, key) ?? defaultValue);

  useEffect(() => store(storageType, key, state), [storageType, key, state]);

  const clearState = () => clear(storageType, key);

  return [state, setState, clearState];
}

function load<T>(storageType: StorageType, key: string): T | null {
  let raw;

  switch (storageType) {
    case "local":
      raw = localStorage.getItem(key);
      break;

    case "session":
      raw = sessionStorage.getItem(key);
      break;
  }

  if (raw === null) {
    return null;
  }

  try {
    return JSON.parse(raw);
  } catch (error) {
    console.error(`Failed to deserialize persisted data for key "${key}": ${error}`);
    return null;
  }
}

function store<T>(storageType: StorageType, key: string, value: T) {
  let serialized: string;

  try {
    serialized = JSON.stringify(value);
  } catch (error) {
    console.error(`Failed to serialize ${value} for storage at key "${key}"`);
    return;
  }

  switch (storageType) {
    case "local":
      localStorage.setItem(key, serialized);
      break;

    case "session":
      sessionStorage.setItem(key, serialized);
      break;
  }
}

function clear(storageType: StorageType, key: string) {
  switch (storageType) {
    case "local":
      localStorage.removeItem(key);
      break;

    case "session":
      sessionStorage.removeItem(key);
      break;
  }
}
