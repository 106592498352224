const OkIcon = () => {
  return (
    <svg height="21" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 0C5.59646 0 0 5.59646 0 12.5C0 19.4035 5.59646 25 12.5 25C19.4035 25 25 19.4035 25 12.5C25 5.59646 19.4035 0 12.5 0ZM12.5 2.71606C17.9035 2.71606 22.2824 7.09783 22.2824 12.5C22.2824 17.9022 17.9035 22.2824 12.5 22.2824C7.09646 22.2824 2.71758 17.9022 2.71758 12.5C2.7176 7.09783 7.09646 2.71606 12.5 2.71606ZM17.2897 6.56433L10.0449 13.8107L7.695 11.4609L5.3894 13.765L7.73925 16.1148L10.0601 18.4357L12.3642 16.1301L19.6106 8.88519L17.2897 6.56433V6.56433Z"
        fill="#05DB91"
      />
    </svg>
  );
};

export default OkIcon;
