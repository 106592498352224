import i18n, { ResourceLanguage, StringMap, TOptions } from "i18next";
import { DefaultResources, initReactI18next, TFuncKey, useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { IndexWithPath, RecursiveKeyOf, StripPrefix, Templatable } from "./utils/typings";
import { config } from "./constants/env";

import en from "./locales/en/translations";
import fr from "./locales/fr/translations";

export const LANGUAGES = {
  en: { nativeName: "English" },
  fr: { nativeName: "Français" },
};

const resources: { [Lang in keyof typeof LANGUAGES]: ResourceLanguage } = {
  en: {
    translation: en,
  },

  fr: {
    translation: fr,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: config.i18Debug,

    saveMissing: true, // Somehow needed to enable the handler below… ⚠️ if we ever plug in a backend
    missingKeyHandler: (lngs, ns, key, fallbackValue) => {
      console.warn(`
        Missing translation key:
          - langs: ${lngs},
          - ns: ${ns},
          - key: ${key},
          - fallback: ${fallbackValue},
      `);
    },

    // This allows us to get warnings in debug mode
    fallbackLng: config.i18Debug ? "dev" : "en",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    resources,
  });

export default i18n;

export type I18nCommonKeys<Path extends TFuncKey> = keyof IndexWithPath<DefaultResources["common"], Path>;

export function useScopedTranslation<Prefix extends TFuncKey>(prefix: Prefix) {
  type Suffix = StripPrefix<`${Prefix}.`, TFuncKey> extends infer A
    ? A extends Templatable
      ? `${Prefix}.${A}` extends TFuncKey
        ? A
        : never
      : never
    : never;

  const { t, i18n, ready } = useTranslation();

  const scopedT = <T extends Suffix>(suffix: T, options?: TOptions<StringMap> | string) =>
    t(`${prefix}.${suffix}`, options);

  return {
    t: scopedT,
    tCommon: t,
    i18n,
    ready,
  };
}

// Statically enforce that translations are conform to the source (en)

type SourceKeys = RecursiveKeyOf<typeof en>;

// prettier-ignore
type TranslationComplete<Translation extends object> =
  SourceKeys extends RecursiveKeyOf<Translation>
  ? RecursiveKeyOf<Translation> extends SourceKeys
    ? true
    : false
  : false;

export const _ASSERT_FRENCH_TRANSLATION_COMPLETE: TranslationComplete<typeof fr> = true;
// …
