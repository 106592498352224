import React from "react";

const ConfidenceLowIcon = () => {
  return (
    <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 15H0V24H6V15Z" fill="#87FCD4" />
      <path d="M14 10H8V24H14V10Z" fill="#87FCD4" />
      <path d="M22 5H16V24H22V5Z" fill="#87FCD4" />
      <path d="M30 0H24V24H30V0Z" fill="#C4C4C4" />
    </svg>
  );
};

export default ConfidenceLowIcon;
