import React from "react";

const ConfidenceHighIcon = () => {
  return (
    <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="15" width="6" height="9" fill="#05DB91" />
      <rect x="8" y="10" width="6" height="14" fill="#05DB91" />
      <rect x="16" y="5" width="6" height="19" fill="#05DB91" />
      <rect x="24" width="6" height="24" fill="#05DB91" />
    </svg>
  );
};

export default ConfidenceHighIcon;
