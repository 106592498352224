const StopIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.20833 9.375V11.4583H15.625V9.375H5.20833ZM10.4167 0C4.66667 0 0 4.66667 0 10.4167C0 16.1667 4.66667 20.8333 10.4167 20.8333C16.1667 20.8333 20.8333 16.1667 20.8333 10.4167C20.8333 4.66667 16.1667 0 10.4167 0ZM10.4167 18.75C5.82292 18.75 2.08333 15.0104 2.08333 10.4167C2.08333 5.82292 5.82292 2.08333 10.4167 2.08333C15.0104 2.08333 18.75 5.82292 18.75 10.4167C18.75 15.0104 15.0104 18.75 10.4167 18.75Z"
        fill="#0A175C"
      />
    </svg>
  );
};

export default StopIcon;
