import { Box, Typography, PropTypes } from "@material-ui/core";
import React, { ReactElement } from "react";

interface Props {
  title?: string;
  align?: PropTypes.Alignment;
  action?: ReactElement;
  child: ReactElement;
}

export default function TitledSection({ title, align, action, child }: Props) {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "stretch", height: "100%" }}>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography align={align} noWrap variant="h6">
          {title}
        </Typography>
        {action}
      </Box>
      <Box m={0.6} />
      {child}
    </div>
  );
}
