import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
}));

export function MenuArrowDown() {
  const classes = useStyles();

  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.icon}
    >
      <path
        d="M0.965848 0.747153C0.807327 0.751383 0.653304 0.800699 0.52186 0.889408C0.390417 0.978117 0.28701 1.1025 0.22378 1.24793C0.160551 1.39336 0.140123 1.55382 0.164896 1.71045C0.189668 1.86708 0.258612 2.0134 0.363634 2.13222L5.35876 7.97045C5.437 8.06214 5.53419 8.13576 5.64364 8.18624C5.75309 8.23672 5.87218 8.26285 5.99271 8.26285C6.11324 8.26285 6.23233 8.23672 6.34178 8.18624C6.45123 8.13576 6.54842 8.06214 6.62666 7.97045L11.6267 2.13222C11.7023 2.04976 11.7606 1.95289 11.798 1.84742C11.8354 1.74195 11.8512 1.63002 11.8445 1.51831C11.8377 1.4066 11.8085 1.29736 11.7586 1.19718C11.7087 1.097 11.6391 1.00797 11.5541 0.935256C11.469 0.862542 11.3702 0.807656 11.2634 0.77397C11.1567 0.740284 11.0443 0.728475 10.9329 0.739175C10.8215 0.749875 10.7134 0.782871 10.615 0.836254C10.5167 0.889638 10.4301 0.962252 10.3604 1.04983L5.9919 6.14911L1.62503 1.04983C1.54506 0.952967 1.44424 0.875499 1.33009 0.823086C1.21595 0.770673 1.09143 0.74467 0.965848 0.747153Z"
        fill="#0A175C"
      />
    </svg>
  );
}
