import { Box, Button, Chip, CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import theme from "../../../config/theme";
import { useScopedTranslation } from "../../../i18n";

type Props = {
  loading?: boolean;
  lifecycle: {
    status: number;
    sync_date?: Date;
    requested_sync: boolean;
    requested_deletion_date?: Date;
  };
  onSyncRequested: () => void;
  disabled?: boolean;
};

const useStyles = makeStyles({
  chipLabel: {
    color: "white",
    fontWeight: "bold",
  },
  buttonUnsync: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.light,
    "&:hover": {
      borderColor: theme.palette.error.dark,
      backgroundColor: "#fff2f2",
    },
  },
});

export default function LifecycleViewer({ loading, lifecycle, onSyncRequested, disabled }: Props) {
  const { t } = useScopedTranslation("sessions_page.details_modal");
  const classes = useStyles();

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Box>
        <Chip
          classes={{ label: classes.chipLabel }}
          color={lifecycle.requested_sync ? "primary" : "default"}
          label={t(`lifecycle_${lifecycle.requested_sync ? "synchronized" : "unsynchronized"}`)}
        />
        {lifecycle.requested_deletion_date && (
          <Box component="span" ml={1}>
            <Typography variant="caption" color="textSecondary">
              {t("requested_deletion")}
            </Typography>
          </Box>
        )}
      </Box>
      {lifecycle.status !== 1 && !lifecycle.requested_deletion_date && (
        <Button
          variant="outlined"
          color="primary"
          classes={{ outlined: lifecycle.requested_sync ? classes.buttonUnsync : undefined }}
          size="small"
          onClick={onSyncRequested}
          disabled={disabled}
        >
          {loading ? (
            <CircularProgress size={15} />
          ) : lifecycle.requested_sync ? (
            t("request_unsync")
          ) : (
            t("request_sync")
          )}
        </Button>
      )}
    </Grid>
  );
}
